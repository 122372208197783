.footerContainer{
    width: 100%;
    height: 100%;
    background-color: var(--clr-black);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
}


.policyContainer{
    width: 400px;
    padding: 30px;
    margin-top: 10px;
    border-top:1px solid var(--clr-dark);
    flex-direction: column;
    display: flex;
    align-items: center;
    /* color: gray; */
  
}

.policyContainer a{
    color: var(--clr-grey);
    font-size: medium;
    font-weight: 300;
}

.privacy:hover{
    color: var(--clr-white);
    cursor: pointer;
}