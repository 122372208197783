@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --fnt-base: 'Jost', sans-serif;
  --clr-robin-blue: #5764de;
  --clr-crazy-blue: #55b3d5;
  --clr-black: #000;
  --clr-md-black: #111;
  --clr-dark: #191919;
  --clr-white: #fff;
  --clr-whitesmoke: #f5f5f5;
  --clr-grey: #8e8e8e;
  --spacing: 0.1rem;
  --gradient: linear-gradient(90deg, rgba(16, 201, 195, 1) 0%, rgba(85, 179, 213, 1) 100%);
  --transition: all 300ms linear;
}

*,
*::after,
*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
html{
  font-size: 10px;
}


div#NagishLiTrigger img{
  width: 30px;
  padding: 0 !important;
  
}
.nagishli-trigger-icon{
 /* background-color: none !important; */
 background-color: #fff;
 border-radius: 50% !important;
 box-shadow: none  !important;
 background: linear-gradient(
 rgba(87, 100, 222, 1) 70%,
 rgba(85, 179, 213, 1)) !important;
 border: none !important;
}


body {
  font-size: 1.9rem;
  line-height: 1.6;
  font-family: var(--fnt-base);
  color: var(--clr-black);
  background-color: var(--clr-md-black);;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main{
  max-width: 100%;
  margin: auto;
}


ul{
  list-style-type: none;
}
a{
  font-size: 20px;
  text-decoration: none;
  color: white;
}
h1, h2, h3, h4, h5, h6{
  letter-spacing: var(--spacing);
  text-transform: capitalize;
}
img{
  width: 100%;
  display: block;
}
.img-cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
button{
  cursor: pointer;
  outline: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1.8rem;
}
input, textarea, select{
  outline: 0;
  border: none;
  resize: none;
}

/* 
.container {
  max-width: 1240px;
  margin: auto;
  height: 100%;
  position: relative;
}  */

.container{
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 2rem;
} 
 

.He{
  text-align: end !important;
}

.En{
  text-align: center;
}


/* 
button {
  font-family: 'Roboto', sans-serif;
  padding: .7rem 1.5rem;
  margin: 1rem 0;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #f8f8f8;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  border-radius: 4px;
}

button:hover {
  background-color: #47dcff;
  border: 1px solid #47dcff;
}

@media screen and (max-width: 940px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }
} */

.translate-effect:hover{
  transform: translateY(-20px);
}