.socialContainer{
    display: flex;
    align-items: center;
    justify-content: center;


}

.tab{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    margin-left: 5px;
  
  }

  .itemIcon{
padding: 10px;

  }

  